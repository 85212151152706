import React from "react"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query SiteTitleHomePageQuery {
      site {
        siteMetadata {
          name
          jobTitle
        }
      }
      placeholderImage: file(relativePath: { eq: "profile_pic.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Welcome" />
      <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
        <Image placeholderImage={data.placeholderImage}  /> {/* <!--- src="profile_pic.jpg" ---!> */}
      </div>
      <div>
        <h1>
          Hey{" "}
          <span role="img" aria-label="icon wave hand">
            👋 
          </span>{" "}
          !
        </h1>
        <p>I'm {data.site.siteMetadata.name}</p>
        <p>
          {data.site.siteMetadata.jobTitle} @ {" "}
          <a href="https://www.konghq.com">KongHQ</a>
        </p>
      </div>
      {/* <Link to="/follow-my-adventure">Follow My Adventures</Link> */}
    </Layout>
  )
}

export default IndexPage
